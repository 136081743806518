<template>
  <div class="Login displayRow">
    <div class="qrCode">
      <p class="title">手机扫码，安全登录</p>
      <div class="code">
        <div class="codeBox">
          <vue-qr 
     :size="140"
     :margin="0"
     :auto-color="true"
     :dot-scale="1"
     :text="appUrl"
/>
        </div>
    <div class="tips displayRow">
        <i class="iconfont icon-iconfontscan"></i>
        <div class="fontSm">
          <p>请使用  微信 扫描二维码</p>
          <p>登录“千电照明商城”</p>
        </div>
        
    </div>
      </div>

</div>
  </div>
</template>

<script>
import {login,loginState,getUserInfo} from '@/assets/js/api.js'
import VueQr from 'vue-qr' 

export default {
  data(){
    return{
        appUrl:'',
        timer:'',
        TokenResult:'',
    }
  },
  components:{
    VueQr 
  },
  watch:{
    TokenResult:{
       immediate: true,
      handler(val){
        if(val !=''){
          this.GetUserInfo(val);
        }
        
      }
    }
  },
  methods:{
    LoginState(str){
      loginState(str).then(res =>{
        if(res.data.code === 200 && res.data.result){
          this.TokenResult = res.data.result;
          localStorage.setItem('token',this.TokenResult);
          clearInterval(this.timer);
          this.timer = '';
        }
      })
    },
    GetUserInfo(val){
         getUserInfo(val).then(res =>{
            if(res.data.code === 200){
              let {avatar,realname} = res.data.result.userInfo;
              localStorage.setItem('avatar',avatar);
              localStorage.setItem('realname',realname);
              this.$router.go(-1);
            }
          });
    },

  },
  created(){
    let nanoidStr = nanoid();
    let params = {state:nanoidStr};
    login(params).then(res =>{
       if (res.data.code === 200) {
         this.appUrl = res.data.result;
         this.timer = setInterval(() => {
           this.LoginState(nanoidStr);
         }, 1000);
       }
    })
  },
}

</script>

<style scoped>
.Login{
  width:100%;
  height: 100%;
 background: #59CDE9;  /* fallback for old browsers */
background: -webkit-linear-gradient(to right,#59CDE9, #0A2A88);  /* Chrome 10-25, Safari 5.1-6 */
background: linear-gradient(to right,#59CDE9, #0A2A88); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
.title{
  font-size: 16px;
    color: #3c3c3c;
    margin-top: 9px;
    padding-bottom: 8px;
    font-weight: 700;

}
.qrCode{
  width: 350px;
  padding: 25px 25px 23px;
  color: #6c6c6c;
 background-color: hsla(0,0%,100%,.9);
  position: relative;
  margin: 0 auto;
}
.code{
  margin-top:30px;
}
.codeBox{
  box-shadow: 0 0 8px #c6c4ce;
  position: relative;
  background-color: #fff;
  opacity: 1;
  width: 150px;
  height: 150px;
  padding:5px;
  box-sizing: border-box;
  margin:0 auto;
}
.tips{
  margin-top:20px;
  justify-content: center;
}
.icon-iconfontscan{
  font-size: 34px;
  margin-right: 10px;
  color:#f40;
}
</style>